import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, BookOpen, PiSquare, Globe, HelpCircle, GraduationCap } from 'lucide-react';

const teachers = [
  { name: 'Ms. Adamo', subject: 'Math', color: 'bg-green-100 border-green-300', icon: PiSquare },
  { name: 'Ms. Biondolillo', subject: 'Language Arts', color: 'bg-yellow-100 border-yellow-300', icon: BookOpen },
  { name: 'Ms. Phillips', subject: 'Unknown', color: 'bg-blue-100 border-blue-300', icon: HelpCircle },
  { name: 'Ms. Vaccaro', subject: 'Social Studies', color: 'bg-purple-100 border-purple-300', icon: Globe }
];

const initialAssessments = {
  '2024-09-16': {
    'Ms. Biondolillo': 'Dhruv behaved well in LA class. He did not flail his arms around or make noises and he did not cry. He came in quietly and said good morning. He then sat down and put his glasses on and did the work with the rest of the class.',
    'Ms. Adamo': 'Today in class, Dhruv needed to finish his math assessment. He did whine a bit that he wanted help. The assessment was open-notebook- Dhruv did not have adequate notes up to this point and performed poorly on the assessment. He did make an effort to take notes today. Dhruv\'s flailing and muttering was much more restrained and he was less disruptive to the class than previously. I hope he keeps it up!',
    'Ms. Phillips': 'He appeared a bit more under control today, though there were a few times when he needed to be redirected and reminded to stay in his seat. However he seemed to tolerate the instructions with less of an emotional reaction than previous days. Hopefully he continues to improve.',
    'Ms. Vaccaro': ''
  },
  '2024-09-17': {
    'Ms. Biondolillo': 'Dhruv did very well in LA class today. He was very calm and listened to what I was saying. We worked in groups of 3 practicing the RACE writing strategy today. Dhruv worked well with his group and helped them to form the answers required.',
    'Ms. Vaccaro': 'Today in Social Studies Dhruv took the first part of his Social Studies chapter 1 test. He sat quietly and waited patiently for me if he had a question. He was also wearing his glasses.',
    'Ms. Adamo': 'Dhruv participated in today\'s activity (creating proportions to scale recipes up or down) well. He showed some of his work and did his best to follow directions. He only felt the need to get out of his seat to ask a question once during class.',
    'Ms. Phillips': 'Dhruv had a few issues today, but overall he continues to show improvement. At lunchtime he became extremely upset when the students were delayed from going outside. He was full out crying. However, when he was asked to stop and calm down, he was able to recover. I have noticed that he appears to be calmer in the morning and earlier part of the afternoon. As the day approaches 1:30 - 2:00 he seems to be slightly more jittery. Do you have any idea why this might be the case? Any information would be helpful.'
  }
};

const SchoolProgressTracker = () => {
  const [currentDate, setCurrentDate] = useState(new Date(2024, 8, 16)); // September 16, 2024
  const [assessments] = useState(initialAssessments);

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  const changeDay = (amount) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + amount);
    
    const minDate = new Date(2024, 8, 16); // September 16, 2024
    const maxDate = new Date(2024, 8, 17); // September 17, 2024

    if (newDate >= minDate && newDate <= maxDate) {
      setCurrentDate(newDate);
    }
  };

  const dateString = currentDate.toISOString().split('T')[0];

  // Sort teachers based on whether they have a comment for the current day
  const sortedTeachers = [...teachers].sort((a, b) => {
    const aHasComment = !!assessments[dateString]?.[a.name];
    const bHasComment = !!assessments[dateString]?.[b.name];
    if (aHasComment && !bHasComment) return -1;
    if (!aHasComment && bHasComment) return 1;
    return 0;
  });

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="bg-gray-200 p-6 rounded-lg shadow-md mb-6">
        <div className="flex justify-center items-center">
          <GraduationCap className="w-10 h-10 mr-3 text-blue-600" />
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800">School Progress Updates</h1>
        </div>
      </div>
      
      <div className="flex justify-between items-center mb-6 bg-white p-4 rounded-lg shadow">
        <button 
          onClick={() => changeDay(-1)} 
          className="p-2 rounded-full hover:bg-gray-200 disabled:opacity-50"
          disabled={currentDate.getTime() === new Date(2024, 8, 16).getTime()}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <div className="text-lg md:text-xl font-semibold">
          {formatDate(currentDate)}
        </div>
        <button 
          onClick={() => changeDay(1)} 
          className="p-2 rounded-full hover:bg-gray-200 disabled:opacity-50"
          disabled={currentDate.getTime() === new Date(2024, 8, 17).getTime()}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          {sortedTeachers.map(({ name, subject, color, icon: Icon }) => (
            <div key={name} className={`p-4 rounded-lg ${color}`}>
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg md:text-xl font-medium">{name}</h3>
                <Icon className="w-6 h-6" title={subject} />
              </div>
              <div className="w-full p-3 border rounded-md bg-white min-h-[40px]">
                {assessments[dateString]?.[name] || `No update from ${name} for this date.`}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SchoolProgressTracker;