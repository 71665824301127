import React from 'react';
import SchoolProgressTracker from './SchoolProgressTracker';

function App() {
  return (
    <div className="w-full min-h-screen bg-gray-100">
      <SchoolProgressTracker />
    </div>
  );
}

export default App;